
















import Vue, { PropType } from 'vue';
import { File } from '@/interfaces/file.interface';
import thumbnailUri from '@/utils/thumbnail-uri';

export default Vue.extend({
  name: 'FileViewerImage',

  props: {
    item: Object as PropType<File>,
    value: Boolean,
    fileUrl: String,
  },

  computed: {
    thumbnailUrl(): string {
      if (!this.item?.thumbnail) {
        return '';
      }

      return thumbnailUri(this.item.thumbnail, 250);
    },
  },
});
