









































































import { File } from '@/interfaces/file.interface';
import OnlineMixin from '@/mixins/online';
import { FavoriteActions } from '@/store/favorite/actions.enum';
import createShareLink from '@/utils/share-link';
import thumbnailUri from '@/utils/thumbnail-uri';
import formatDate from '@/utils/transform/date';
import fileSize from '@/utils/transform/file-size';
// eslint-disable-next-line import/order
import Vue, { PropType } from 'vue';
// eslint-disable-next-line import/order
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'GridFileItem',

  mixins: [OnlineMixin],

  data() {
    return {
      controller: null as null | AbortController,
      showImage: false,
      preloadDataUrl: null as null | string,
      thumbnailDataUrl: null as null | string,
    };
  },

  props: {
    item: Object as PropType<File>,
  },

  computed: {
    ...mapGetters('auth', ['token', 'userId']),
    ...mapGetters('locale', { localeId: 'currentId' }),
    ...mapGetters('favorite', ['isFavorite']),

    preloadThumbnailUrl(): string {
      return this.thumbnailUrl.replace('/250/', '/50/');
    },

    thumbnailUrl(): string {
      if (!this.item?.thumbnail) {
        return '';
      }

      return thumbnailUri(this.item.thumbnail, 250);
    },

    fileSize(): string {
      return fileSize(+this.item.size);
    },

    modifiedDate(): string {
      return formatDate(this.item.modified);
    },

    favorite(): boolean {
      return this.isFavorite(this.item.id);
    },

    shareable(): boolean {
      return Boolean(+this.item.shareable);
    },
  },

  methods: {
    ...mapActions('favorite', {
      addFavorite: FavoriteActions.CREATE,
      removeFavorite: FavoriteActions.DELETE,
    }),

    async cacheImage() {
      if (this.showImage) {
        return;
      }

      this.controller = new AbortController();
      const { signal } = this.controller;

      try {
        const [preloadRequest, request] = await Promise.all([
          fetch(this.preloadThumbnailUrl, { signal }),
          fetch(this.thumbnailUrl, { signal }),
        ]);

        const preloadBlob = await preloadRequest.blob();
        this.preloadDataUrl = URL.createObjectURL(preloadBlob);

        const blob = await request.blob();
        this.thumbnailDataUrl = URL.createObjectURL(blob);

        this.showImage = true;
      } catch (error) {
        if (error.code !== DOMException.ABORT_ERR) {
          console.error(error);
        }
      }
    },

    handleIntersect(entries: { isIntersecting: boolean }[]) {
      const { isIntersecting } = entries[0];

      if (!isIntersecting && this.controller) {
        this.controller.abort();
      } else if (isIntersecting) {
        this.cacheImage();
      }
    },

    openFile() {
      this.$emit('open-file', this.item);
    },

    copyShareLink() {
      const link = createShareLink(this.item, this.localeId, this.userId);
      navigator.clipboard.writeText(link);
      this.$emit('snackbar', this.$t('shareLink.txtSnackbar'));
    },

    toggleFavorite() {
      if (this.favorite) {
        this.removeFavorite(this.item.id);
      } else {
        this.addFavorite(this.item);
      }
    },
  },
});
